<style>
.sub-header {
  font-size: 16px;
  font-weight: 500;
}
.deletedcol {
  background-color: #ff7f7f;
}
</style>
<template>
  <div>
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>

    <div v-if="!getLoadingData">
      <div class="card form-body">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5
                for=""
                class="detail"
                style="font-weight: 900; margin-left:1rem;"
              >
                {{ jobData.title }}
                <span
                  v-if="jobData.status != 'Open'"
                  class="badge badge-danger"
                  style="font-weight: 900; margin-left:1rem;"
                >
                  {{ jobData.status }}
                </span>
                <span
                  v-if="jobData.status == 'Open'"
                  class="badge badge-success"
                  style="font-weight: 900; margin-left:1rem;"
                >
                  {{ jobData.status }}
                </span>
              </h5>
              <h6 for="" class="detail" style=" margin-left:1rem;">
                Link :
                <a :href="jobData.job_link" target="_blank">{{
                  jobData.job_link
                }}</a>
                <v-icon right @click.stop.prevent="copyLink"
                  >content_copy</v-icon
                >
              </h6>
              <input type="hidden" id="job_link1" :value="jobData.job_link" />
            </div>

            <div class="col-md-4">
              <div class="" style="float:right;">
                <button
                  class="btn btn-sm btn-danger mr-3"
                  @click="goBack()"
                  style="float: right"
                >
                  Back
                </button>
                <span class="ml-5" v-if="jobData.status == 'Open'"
                  ><button
                    @click="SendJobMail(jobData.id)"
                    class="btn btn-info btn-sm mr-3"
                  >
                    Send Mail
                  </button></span
                >
                <button
                  @click="editJob(jobData.id)"
                  class="btn btn-info btn-sm mr-3"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-4">
              <ul class="list-unstyled">
                <li>
                  <label for="">
                    Service :
                  </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    {{ jobData.service_name }}
                  </label>
                </li>
                <li>
                  <label for="">Email Status : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 400; margin-left:5px;"
                  >
                    <span
                      v-if="jobData.email_sent != 1"
                      class="badge badge-danger"
                    >
                      Not Sent
                    </span>
                    <span
                      v-if="jobData.email_sent == 1"
                      class="badge badge-success"
                    >
                      Sent
                    </span></label
                  >
                </li>
                <li>
                  <label for=""> Schedule Date Time : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    <span v-if="jobData.schedule_date != null">
                      {{ jobData.schedule_date | dateFormat }}
                    </span>
                    <span v-if="jobData.schedule_time != null">
                      {{ jobData.schedule_time }}
                    </span>
                    <span v-if="jobData.schedule_date == null">
                      N/A
                    </span></label
                  >
                </li>
                <li>
                  <label for=""> Payment By : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    <span>{{ jobData.payment_by }}</span>
                  </label>
                </li>
                <li>
                  <label for=""> Operator : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    <span>{{ jobData.operator_name }}</span>
                  </label>
                </li>
                <li>
                  <label for=""> Service Location : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    <span>{{ jobData.service_location_full_address }}</span>
                  </label>
                </li>
              </ul>
            </div>

            <div class="col-md-4">
              <h6 class="text-success sub-header">Client Details</h6>
              <hr />
              <ul class="list-unstyled">
                <li>
                  <label for="">
                    Name :
                  </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                    >{{ jobData.client_name }}
                  </label>
                </li>
                <li>
                  <label for="">Email : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    {{ jobData.client_email }}</label
                  >
                </li>
                <li>
                  <label for=""> Address : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    {{ jobData.client_full_address }}</label
                  >
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <h6 class="text-success sub-header">Contact Details</h6>
              <hr />
              <ul class="list-unstyled">
                <li>
                  <label for="">
                    Name :
                  </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                    >{{ jobData.contact_person }}
                  </label>
                </li>
                <li>
                  <label for="">Email : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    {{ jobData.contact_email }}</label
                  >
                </li>
                <li>
                  <label for=""> Phone No : </label>
                  <label
                    for=""
                    class="detail"
                    style="font-size: 13px; font-weight: 900; margin-left:5px;"
                  >
                    {{ jobData.contact_phone_no }}</label
                  >
                </li>
              </ul>
            </div>
          </div>

          <hr />
          <appointmentDetailModal
            :key="key"
            v-if="appointmentDetailModalShow"
            v-on:closemodal="closeModal"
            v-on:updatepaymentstatus="updatepaymentstatus"
            :loading-data="loadingData"
            :has-data="hasData"
            :show-modal="appointmentDetailModalShow"
            :appointment-data="appointmentData"
            :index-num="currentIndex"
            :show-qr-code="showQrCode"
            :token="token"
            @reLoadTable="reTableReload"
          ></appointmentDetailModal>
          <div class="row" v-if="getLoadingAppointment">
            <div class="col-md-12 text-center">
              <v-data-loader></v-data-loader>
            </div>
          </div>
          <div
            class="row"
            v-if="jobAppointments.length > 0 && !getLoadingAppointment"
          >
            <div class="col-md-12">
              <a
                @click="refreshData()"
                href="#"
                class="btn btn-secondary font-weight-bolder"
                style="float: right"
              >
                <i class="text-white fa fa-refresh"></i> Refresh</a
              >
            </div>
            <div class="col-md-12">
              <v-table :columns="columns">
                <tr
                  v-for="(item, index) in jobAppointments"
                  :key="index"
                  :class="[item.e_deleted ? 'deletedcol' : '']"
                >
                  <td style="width:5%;">
                    <h6 style="font-size: 13px; font-weight: 400">
                      {{ datetimeFormatAmPm(item.created_at) }}
                    </h6>
                    <!-- <h6
                      style="font-size: 13px; font-weight: 400"
                      v-if="item.schedule_date != null"
                    >
                      {{ item.schedule_date | dateFormat }}
                    </h6> -->
                    <!-- <h6
                      style="font-size: 13px; font-weight: 400"
                      v-if="item.schedule_date != null"
                    >
                      {{ item.start_time | timeFormat(item.schedule_date) }}
                    </h6>
                    <h6
                      style="font-size: 13px; font-weight: 400"
                      v-if="item.schedule_date == null"
                    >
                      N/A
                    </h6> -->
                  </td>

                  <td style="width:7%;">
                    <h6 style="font-size: 13px; font-weight: 400">
                      {{
                        item.enroll_date
                          ? datetimeFormatAmPm(item.enroll_date)
                          : "-"
                      }}
                    </h6>
                  </td>
                  <td class="text-right">
                    <a
                      href="javascript:void;"
                      @click="
                        openAppointmentDetail(item.id, item.token, index, true)
                      "
                    >
                      {{ item.id }}</a
                    >
                  </td>
                  <td class="text-right">
                    {{ item.enrollment_id }}
                  </td>
                  <td>
                    <!-- <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.applicant }}</a
                > -->
                    {{ item.applicant }}
                  </td>
                  <td class="text-right">
                    ${{ item.amount | toFixedTwoDigit }}
                  </td>
                  <td class="text-center">
                    <div
                      class="input-group"
                      v-if="
                        item.change_status_flag && item.appt_status != 'Cancel'
                      "
                    >
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="item.new_status"
                      >
                        <option value="P" :selected="item.appt_status == 'P'">
                          Pending
                        </option>
                        <option value="C" :selected="item.appt_status == 'C'">
                          Completed
                        </option>
                        <option value="U" :selected="item.appt_status == 'U'">
                          No-Show
                        </option>
                        <option
                          value="Cancel"
                          :selected="item.appt_status == 'Cancel'"
                        >
                          Cancel
                        </option>
                      </select>
                      <div class="input-group-append">
                        <label
                          v-if="item.appt_status != item.new_status"
                          class="input-group-text"
                          style="cursor: pointer"
                          @click="
                            updateStatusChangesFlag(
                              item.id,
                              index,
                              item.new_status
                            )
                          "
                          for="inputGroupSelect02"
                          ><i class="fa fa-check text-success"></i
                        ></label>
                        <label
                          class="input-group-text"
                          style="cursor: pointer"
                          @click="closeChangeStatusFlag(index)"
                          for="inputGroupSelect02"
                          ><i class="fa fa-times text-danger"></i
                        ></label>
                      </div>
                    </div>
                    <span
                      v-if="!item.change_status_flag"
                      style="cursor: pointer"
                      @click="changeStatusOpen(item.id, index)"
                      class="badge text-white"
                      :class="' badge-' + statusColor(item.appt_status)"
                      >{{ item.appt_status | apptStatus }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-white badge badge-primary"
                      style="cursor: pointer"
                      @click="
                        openAppointmentDetail(item.id, item.token, index, false)
                      "
                    >
                      {{ item.payment_type | payementStatus }}
                    </span>
                  </td>
                  <td class="text-center">
                    <i
                      class="text-success fa fa-check"
                      v-if="item.payment_status == 'P'"
                    ></i>
                    <i
                      class="text-danger fa fa-times"
                      v-if="item.payment_status == 'U'"
                    ></i>
                    <span
                      v-if="item.payment_status == 'Refund'"
                      style="cursor: pointer"
                      class="badge text-white badge-danger"
                    >
                      Refunded</span
                    >
                  </td>
                  <td class="pr-0 text-right" style="width: 120px">
                    <a
                      v-if="
                        item.payment_status == 'P' &&
                          item.appt_status != 'Cancel'
                      "
                      href="javascript:void()"
                      @click="openInvoice(item.token)"
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      >Invoice
                      <i class="fa fa-arrow-right ml-1"></i>
                    </a>
                  </td>
                </tr>
              </v-table>
            </div>
          </div>
          <div
            class="row"
            v-if="jobAppointments.length < 1 && !getLoadingAppointment"
          >
            <div class="col-md-12 text-center">
              <h4>no appointment(s) found</h4>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="pagination.total_page > 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page > 1"
        :pagination="pagination"
      ></vl-pagination>
    </div>
    <div class="row" v-if="getLoadingData">
      <div class="col-md-12 text-center">
        <v-data-loader></v-data-loader>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {
  FETCH_SELF_APPOINMENT,
  APPOINTMENT_LOGS,
  FETCH_CLIENT_JOB_DETAILS,
  SEND_CLIENT_JOB_MAIL,
  UPDATE_APPOINTMENT_STATUS,
  FETCH_CLIENT_JOB_APPOINTMENTLIST
} from "@/core/services/store/actions.type";
import * as PDF417 from "pdf417-generator";
import vTable from "@/includes/table/Datatable";
import vlPagination from "@/includes/table/Pagination";
import vDataLoader from "@/components/frontend/spinner.vue";
import appointmentDetailModal from "@/components/frontend/modal/appointmentdetail";

export default {
  components: {
    vTable,
    appointmentDetailModal,
    vlPagination,
    vDataLoader
    // downloadExcel: JsonExcel
  },
  computed: {
    ...mapGetters(["currentUser", "getJobDetails"])
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
    },
    dateFormat(date) {
      if (date == null) {
        return;
      }
      return moment(date).format("MM/DD/YYYY");
    },
    payementStatus(status) {
      if (status == "C") {
        return "Card";
      }
      if (status == "A") {
        return "Abbreviation";
      }
      if (status == "O") {
        return "On-site";
      }
      if (status == "CP") {
        return "Company Payment";
      }
      if (status == "Cancel") {
        return "Cancel";
      }
    },

    apptStatus(status) {
      var s = "";
      switch (status) {
        case "P":
          s = "Pending";
          break;

        case "C":
          s = "Completed";
          break;

        case "U":
          s = "No-Show";
          break;
        case "Cancel":
          s = "Canceled";
          break;
      }
      return s;
    }
  },
  data() {
    return {
      jobData: [],
      getLoadingAppointment: true,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      },
      appointmentDetailModalShow: false,
      jobAppointments: [],
      client_slug: "",
      selectedAppId: 0,
      showQrCode: false,
      currentIndex: -1,
      loadingData: false,
      hasData: false,
      getLoadingData: true,
      saveDataLoader: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Mobile Service",
          route: "client.job.list"
        },
        {
          id: 3,
          title: "Details",
          route: ""
        }
      ],
      columns: [
        {
          name: "date",
          width: "5%%",
          label: "App Date",
          class: ""
        },
        {
          name: "Enr_date",
          width: "6%",
          label: "Enr. Date",
          class: ""
        },

        {
          name: "App_no",
          width: "5%",
          label: "App No.",
          class: "text-right"
        },

        {
          name: "Enr_no",
          width: "5%",
          label: "Enr. No",
          class: "text-right"
        },
        {
          name: "applicant",
          width: "25%",
          label: "Applicant",
          class: ""
        },
        {
          name: "amount",
          width: "10%",
          label: "Amount",
          class: "text-right"
        },
        {
          name: "status",
          width: "15%",
          label: "Status",
          class: "text-center"
        },
        {
          name: "payment",
          width: "10%",
          label: "Payment",
          class: ""
        },
        {
          name: "Paid",
          width: "5%",
          label: "Paid",
          class: "text-center"
        },
        {
          name: "action",
          width: "10%",
          label: "",
          class: ""
        }
      ]
    };
  },

  created() {
    this.client_slug = this.$route.params.client_slug;
    this.job_id = this.$route.params.job_id;
    /* console.log(this.job_id); */
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getLoadingData = true;
    this.$store
      .dispatch(FETCH_CLIENT_JOB_DETAILS, {
        id: parseInt(this.job_id),
        list: true
      })
      .then(data => {
        this.getLoadingData = false;
        this.jobData = data.data;
      });
    this.getData();
    // this.$store
    //   .dispatch(FETCH_CLIENT_JOB_DETAILS, {
    //     id: parseInt(this.job_id),
    //     list: true
    //   })
    //   .then(data => {
    //     this.getLoadingData = false;
    //     var data_ = data.data;
    //     this.page: this.pagination.current_page,
    //     this.jobData = data_;
    //     this.jobAppointments = data_.appointments;
    //   });
    // this.Search();
  },
  methods: {
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.getData();
    },
    async getData() {
      this.getLoadingAppointment = true;
      await this.$store
        .dispatch(FETCH_CLIENT_JOB_APPOINTMENTLIST, {
          id: parseInt(this.job_id),
          page: this.pagination.current_page
        })
        .then(data => {
          this.getLoadingAppointment = false;

          this.pagination.total_records = data.total_record;
          this.pagination.total_page = data.page_range;
          this.pagination.received_per_page_data = data.data.length;
          this.jobAppointments = data.data;
        });
    },
    closeModal() {
      this.appointmentDetailModalShow = false;
    },
    editJob(id) {
      this.$router.push({
        name: "client.job.form",
        params: { client_slug: this.client_slug, job_id: id }
      });
    },
    SendJobMail(id) {
      this.loadingMessage = "please wait <br> While sending the email...";
      var data = { id: id };
      this.saveDataLoader = true;
      this.$store
        .dispatch(SEND_CLIENT_JOB_MAIL, data)
        .then(res => {
          this.saveDataLoader = false;
          this.jobData.email_sent = res.data.email_sent;

          this.$toastr.s(res.msg);
        })
        .catch(res => {
          this.saveDataLoader = false;
          this.$toastr.e(res);
        });
    },
    openAppointmentDetail(appId, token, index, showQrCode) {
      if (
        this.jobAppointments[index].cancel == 1 &&
        this.currentUser.u.is_client
      ) {
        return 0;
      }
      this.showQrCode = showQrCode;
      this.currentIndex = index;
      this.appointmentDetailModalShow = true;
      this.selectedAppId = appId;
      this.loadingData = true;
      var data = {
        token: token,
        appointment_id: appId
      };

      this.$store
        .dispatch(FETCH_SELF_APPOINMENT, data)
        .then(res => {
          this.appointmentData = res.data;
          this.token = token;

          this.loadingData = false;
          this.hasData = res.hasData;
          this.key += 1;

          if (showQrCode) {
            var DBB = this.$moment(res.data.dob).format("MMDDYYYY");
            var DBC = res.data.gender == "Female" ? 2 : 1;
            var DAU = ((res.data.height * 12) / 100).toFixed(2);
            var value =
              "@\n\nANSI 636020090102DL00410279ZC03200010DLDAQ171774992\nDCA\nDCB\nDCD\nDBA\nDCS" +
              res.data.lname +
              "\nDAC" +
              res.data.fname +
              "\nDAD" +
              res.data.mname +
              "\nDBD\nDBB" +
              DBB +
              "\nDBC" +
              DBC +
              "\nDAY" +
              res.data.eye_color +
              "\nDAU0" +
              DAU * 100 +
              " in\nDAG" +
              "\nDAI" +
              res.data.city +
              "\nDAJ" +
              res.data.address +
              "\nDAK" +
              res.data.zipcode +
              "\nDAQ\nDCF\nDCG\nDDE\nDDF\nDDG";
            var canvas = document.getElementById("barcode");
            PDF417.draw(value, canvas);
            res.data.barcode_base64 = canvas.toDataURL("image/png");
          }

          this.appointmentData = res.data;
          this.$store.dispatch(APPOINTMENT_LOGS, {
            app_id: appId
          });
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    closeChangeStatusFlag(index) {
      this.selectedAppId = 0;
      this.jobAppointments[index].change_status_flag = false;
    },
    updateStatusChangesFlag(appId, index, appt_status) {
      var status = this.jobAppointments[index].new_status;
      if (status != this.jobAppointments[index].appt_status) {
        if (status == "Cancel") {
          let message = `You want to cancel the appointment no. ${this.jobAppointments[index].id}`;
          this.$swal
            .fire({
              title: "Are you sure?",
              text: message,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              cancelButtonText: "No"
            })
            .then(result => {
              if (result.isConfirmed) {
                this.storeStatusChange(appId, appt_status, index, status);
              } else {
                this.closeChangeStatusFlag(index);
              }
            });
        } else {
          this.storeStatusChange(appId, appt_status, index, status);
        }
      } else {
        this.jobAppointments[index].appt_status = status;
        this.closeChangeStatusFlag(index);
      }
    },
    storeStatusChange(appId, appt_status, index, status) {
      this.$store
        .dispatch(UPDATE_APPOINTMENT_STATUS, {
          app_id: appId,
          appt_status: appt_status
        })
        .then(res => {
          this.jobAppointments[index].appt_status = status;
          if (status == "Cancel") {
            this.jobAppointments[index].cancel = 1;
            // this.appoinments[index].payment_status = "U";
          } else {
            this.jobAppointments[index].cancel = 0;
            // this.appoinments[index].payment_status = "P";
          }

          this.closeChangeStatusFlag(index);
          this.$toastr.s(res.msg);
        })
        .catch(err => {
          this.$toastr.e(err.msg);
        });
    },
    copyLink() {
      let testingCodeToCopy = document.querySelector("#job_link1");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        document.execCommand("copy");

        this.$toastr.s("Link copied successfully");
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    openInvoice(token) {
      // alert(token);
      this.$router.push({
        name: "client.invoice.details",
        params: { slug: this.$route.params.client_slug, token: token }
      });
    },
    updatepaymentstatus(i, ps, is_drug_testing) {
      if (is_drug_testing) {
        this.refreshData();
      }
      this.jobAppointments[i].payment_status = ps;
    },
    changeStatusOpen(appointMentId, index) {
      // console.log(this.jobAppointments[index]);
      if (this.jobAppointments[index].cancel == 0) {
        this.selectedAppId = appointMentId;
        this.jobAppointments[index].change_status_flag = true;
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.getData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.getData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.getData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.getData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.getData();
    },
    goBack() {
      this.$router.push({
        name: "client.job.list"
      });
    },
    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    }
  }
};
</script>
